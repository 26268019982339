import { Component, Input } from '@angular/core';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { IShip } from '../../interface/ship.interface';
import { ShipsService } from '../../service/ships.service';

@Component({
  selector: 'app-ships-table',
  templateUrl: './ships-table.component.html',
  styleUrls: ['./ships-table.component.scss'],
})
export class ShipsTableComponent extends TableComponent<IShip, string> {
  readonly headings = ['name', 'imoNumber', 'flag', 'companyName', 'updatedAt'];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  constructor(
    sessionService: SessionService,
    private readonly shipsService: ShipsService,
  ) {
    super(sessionService);
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<IShip>> {
    return this.shipsService.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    });
  }
}
